@import '~antd/dist/antd.css';

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

td.customCell0 {
    background: #7f0000;
}

td.customCell1 {
    background-color: #1c313a;
}
.customCell2 {
    background-color: #003300;
}
.customCell3 {
    background-color: #002f6c;
}
.customCell4 {
    background-color: #12005e;
}
.customCell5 {
    background-color: #560027;
}
.customCell6 {
    background-color: #00363a;
}
.customCell7 {
    background-color: #003d00;
}
.customCell8 {
    background-color: #bc5100;
}

.ant-table-tbody > tr.ant-table-row:hover > td.customCell0:hover {
    background: #7f0000;
}
.ant-table-tbody > tr.ant-table-row:hover > td.customCell1:hover {
    background: #1c313a;
}
.ant-table-tbody > tr.ant-table-row:hover > td.customCell2:hover {
    background: #003300;
}
.ant-table-tbody > tr.ant-table-row:hover > td.customCell3:hover {
    background: #002f6c;
}
.ant-table-tbody > tr.ant-table-row:hover > td.customCell4:hover {
    background: #12005e;
}
.ant-table-tbody > tr.ant-table-row:hover > td.customCell5:hover {
    background: #560027;
}
.ant-table-tbody > tr.ant-table-row:hover > td.customCell6:hover {
    background: #00363a;
}
.ant-table-tbody > tr.ant-table-row:hover > td.customCell7:hover {
    background: #003d00;
}
.ant-table-tbody > tr.ant-table-row:hover > td.customCell8:hover {
    background: #bc5100;
}
